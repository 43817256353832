<script setup lang="ts">
import type { RouteMap, RouteLocationRaw } from "#vue-router"

export type RouteName<Name extends keyof RouteMap = keyof RouteMap> = Name

// We need to explicitly define the "name" property within `RouteLocationRaw`
// to ensure correct type matching with NuxtLink's `to` prop,
// as `RouteLocationRaw` accepts a name property but does not inherently include it.
type StepLink = { name: RouteName } & RouteLocationRaw

export type Step = {
  title: string
  link: StepLink
  hidden?: boolean
}

const { completedSteps, steps } = defineProps<{
  completedSteps: RouteName[]
  steps: Step[]
}>()

const route = useRoute()

const isCurrent = (path: Step): boolean => {
  return route.name === path.link.name
}

function isStepCompleted(step: Step) {
  return completedSteps.includes(step.link.name)
}

// Helpers to derive classes from step status
const textClass = (step: Step): string => {
  if (isCurrent(step)) {
    return "text-gray-900"
  }
  return isStepCompleted(step) ? "text-violet-600" : "text-gray-500"
}

const stepClass = (step: Step): string => {
  if (isCurrent(step)) {
    return "bg-gray-900 text-white"
  }
  return isStepCompleted(step)
    ? "bg-violet-600/10 border-alpha-black-25"
    : "bg-gray-50"
}

const visibleSteps = computed(() => steps.filter((step) => !step.hidden))
</script>

<template>
  <ul class="flex items-center gap-2">
    <li
      v-for="(step, idx) in visibleSteps"
      :key="idx"
      class="group flex items-center gap-2"
    >
      <NuxtLink :to="step.link" class="flex items-center gap-3">
        <div
          class="flex items-center gap-2 text-sm font-medium"
          :class="textClass(step)"
        >
          <span
            class="inline-flex size-7 shrink-0 items-center justify-center rounded-full border border-transparent"
            :class="stepClass(step)"
          >
            <!-- If step is complete and not the current one, show a check icon; otherwise show the step number -->
            <SvgoPikaCheck
              v-if="isStepCompleted(step) && !isCurrent(step)"
              class="size-4"
            />
            <template v-else>
              {{ idx + 1 }}
            </template>
          </span>

          <span class="hidden lg:inline">{{ step.title }}</span>
        </div>
      </NuxtLink>

      <!-- Separate the steps visually. Hide the line after the last step -->
      <div class="h-px w-4 bg-slate-900/20 group-last:hidden md:w-6" />
    </li>
  </ul>
</template>
